import * as React from "react"
import { graphql } from "gatsby"
import { FiClock, FiUser } from "react-icons/fi"
import { Layout, Seo } from "../components"

const BlogPostTemplate = ({ data }) => {
  const { title, headerImage, date } = data.blogPost.frontmatter

  return (
    <Layout>
      <Seo title="Strona główna" />

      <div className="active-dark">
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{ backgroundImage: `url(${headerImage})` }}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">{title}</h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      {date}
                    </li>
                    <li>
                      <FiUser />
                      Kamil Cyba
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-blog-details pt--110 pb--70 bg_color--1 brand-separation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div
                    className="inner"
                    dangerouslySetInnerHTML={{ __html: data.blogPost.html }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    blogPost: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        headerImage
        thumbnail
        date(formatString: "D MMMM YYYY", locale: "pl")
      }
    }
  }
`

export default BlogPostTemplate
